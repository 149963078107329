import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Faq from "./style"
import { Link } from "gatsby"
import AccordionDisplay from "./components/FaqWidget"
const FaqSection = ({id}) => {


  return (
    <Faq id={id}>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
              <Faq.Box mb="58px">
              <Faq.Title as="h1">Frequently Asked Question</Faq.Title>
              </Faq.Box>
          </Col>
        </Row>
        {/* Faqs */}
          <section className="faq-sec">
            <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
            <div className="my-3">
          <AccordionDisplay 
           title="What is a “seat”?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>1 seat = 1 LinkedIn account for a LinkedIn-only plan and <br/> 1 seat = 1 LinkedIn account + 1 Mailbox for a LinkedIn+Email plan</p>
         </AccordionDisplay>
         </div>
         <div className="my-3">
         <AccordionDisplay 
           title="Does We-Connect integrate with free or paid versions of LinkedIn?  What about Recruiter or Sales Navigator?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes. We-Connect works with: <br/>
                  <li>LinkedIn Basic (free)</li>
                  <li>Sales Navigator (Core/Advanced/Advanced Plus)</li>
                  <li>Recruiter / Recruiter Lite</li>
                  <li>Premium (Career/Business)</li>
                  Most of our customers find that they get the best value from using We-Connect in conjunction with one of the paid LinkedIn subscriptions; however,<br/> We-Connect will also work with the free version.</p>
         </AccordionDisplay>
         </div>
         <div className="my-3">
         <AccordionDisplay 
           title="Does We-Connect integrate with my CRM?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Please visit our <Link to="/integrations">Integrations page</Link> to view our current integrations.  We also integrate with Zapier for additional connections.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="What type of LinkedIn actions can I automate using We-Connect?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p className="px-4 py-6 pt-0 ml-4 -mt-4 dark:text-gray-400">We-Connect allows you to automate 11 different types of LinkedIn actions:<br/><br/>
                  (1) Send connection requests.<br/>
                  (2) Message 1st connections.<br/>
                  (3) Auto endorse.<br/>
                  (4) Auto follow.<br/>
                  (5) Auto visit. <br/>
                  (6) Send free InMail to open profiles. <br/>
                  (7) Message group members. <br/>
                  (8) Message event attendees. <br/>
                  (9) Like a post. <br/>
                  (10) Auto withdraw connection request. <br/>
                  (11) Send follow-up message.<br/>
                  (12) Automated Birthday greetings.<br/>
                  (13) Automated greetings for job anniversary.<br/>
                  (14) Automated greetings for new job role.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="Is We-Connect safe for my LinkedIn account?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes, We-Connect is designed with the safety and security of your LinkedIn account in mind. Here are a few key points to help ensure safety:<br/><br/>
                  <li>Compliance with LinkedIn Policies:<br/> We-Connect operates within the limits set by LinkedIn to prevent account restrictions or bans. It ensures that the connection requests and messages are sent at a rate that mimics human behavior.</li><br/>
                  <li>Data Privacy:<br/> We-Connect uses industry-standard encryption to protect your data. Your LinkedIn credentials and campaign data are stored securely, and we adhere to strict privacy policies to ensure your information is never shared or misused.</li><br/>
                  <li>Account Protection:<br/> We-Connect provides features like dedicated IP addresses and cloud-based infrastructure to help prevent your account from being flagged for unusual activity. This helps maintain the integrity and safety of your LinkedIn account while using our services.</li><br/>
                  <li>Real-Time Monitoring:<br/> Our system continuously monitors the activity to ensure that all operations are running smoothly and safely. Any irregularities are detected and addressed promptly to prevent any impact to your LinkedIn account.</li><br/>
                  <li>User Control:<br/> You have full control over your campaigns and activities on We-Connect. You can set limits, schedules, and monitor performance, ensuring that everything aligns with LinkedIn's usage guidelines.</li><br/>
                  We-Connect prioritizes the safety and security of your LinkedIn account, allowing you to grow your network and enhance your professional relationships without compromising security.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="Does We-Connect offer a dedicated IP address for each account?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes, we automatically assign a dedicated country-based IP address. You can log in to We-Connect from any location or device without the risk of being detected.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="Can I export or download my campaign data?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes, you can export your campaign data, profiles, and lists, including emails and phone numbers. Use the data for retargeting, email campaigns, multi-channel campaigns, and analysis. Segment the data by status, such as connected, replied, endorsed, etc.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="Can I upload my own lists?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes. You can upload a csv file containing list of contacts with their LinkedIn profile URLs. If you are using a CRM integration, you can also import LinkedIn profile URLs from your CRM.</p>
         </AccordionDisplay>
         </div><div className="my-3">
         <AccordionDisplay 
           title="Can I run multiple campaigns at the same time?"
           arrowPosition="right"
           forceDefaultHide={true}
          >
           <p>Yes. You can run multiple campaigns at the same time. However, you do need to have a unique list or lists for each campaign.</p>
         </AccordionDisplay>
         </div>
         </div>
         </section>
         {/* Faqs */}
          {/* Button  */}
          <Faq.ButtonGroup>
            <Faq.Text>
            Still have questions?
            </Faq.Text>
            &nbsp;
            <a className="intercom_link" href="#">
              
               Please start a chat now.
              
            </a>  
          </Faq.ButtonGroup>
          {/* Button End */}       
      </Container>
    </Faq>
  )
}

export default FaqSection
