import React from 'react';
import { InlineWidget } from 'react-calendly';

const PopupComponent = () => {
  return (
    <div className="inline-widget">
      <InlineWidget
        url="https://calendly.com/camden-we-connect/we-connect-demo" 
      />
    </div>
  );
};

export default PopupComponent;