import React from "react";
import { PageWrapper } from "~components/Core";
import HeaderButton from '~sections/project/Header';
import FaqSection from "~sections/pricing/Faq";
import PricingSection from "~sections/pricing/Pricing";
import FooterOne from "~sections/marketing/FooterOne";
import CtaSection from "~sections/marketing/Cta";
import Testimonial from "~sections/customer-stories/Testimonial";
import { Helmet } from "react-helmet";

const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function PricingTwo() {
  return (
    <>
    <Helmet>
        <title>We-Connect | Pricing</title>
        <meta name="description" content="We-Connect offers cloud-based outreach for your LinkedIn prospecting. Pay as you go with a gimmick-free monthly or yearly subscription."/>
        <meta property="og:title" content="We-Connect | Pricing"/>
        <meta property="og:description" content="We-Connect offers cloud-based outreach for your LinkedIn prospecting. Pay as you go with a gimmick-free monthly or yearly subscription."/>
        <meta property="og:image" content="https://we-connect.io/we-connect_pricing-page.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/pricing"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | Pricing"/>
        <meta property="twitter:description" content="We-Connect offers cloud-based outreach for your LinkedIn prospecting. Pay as you go with a gimmick-free monthly or yearly subscription."/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect_pricing-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header} >
        <PricingSection/>
        <FaqSection id="faq"/>
        <Testimonial />
        <CtaSection />
        <FooterOne />
    </PageWrapper>
    </>
  )
}
