import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Pricing = styled(Box)`
    padding-top: 85px;
    padding-bottom: 40px;
    @media (min-width:575px){
        padding-top: 55px;
        padding-bottom: 60px;
    }
    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .card{
        width: 227px;
        margin: 0 auto;
        float: none;
    }
`
Pricing.Button = styled(Box).attrs({className:"pricing-btn"})`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (min-width: 992px){
        justify-content: center;
    }
    label{
    font-size: 18px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 25px;
        color: #262729;
        margin: 0 15px;
    }
    .btn-toggle {
        position: relative;
        width: 46px !important;
        height: 26px;
        border-radius: 15px;
        border: none;
        background-color: #0f89ff !important;
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
          background-color: #0f89ff !important;
          background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }
        &:checked{
          background-color: #0f89ff !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
         
        }
      }
      .badge {
        margin-bottom: 0;
        color: $shark;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        min-width: 96px;
        height: 30px;
        border-radius: 15px;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 22px;
        @media (min-width:1200px) {
            bottom: -12px;
            left: 295px;
        }
        @media (min-width:1400px) {
            bottom: -12px;
            left: 340px;
        }
      }
`
Pricing.Toggle = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media (min-width: 992px){
        justify-content: center;
    }
.badge {
    margin-bottom: 0;
    color: $shark;
    display: flex;
    position: absolute;
    bottom: -35px;
    left: 225px;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    height: 30px;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 22px;
    @media (min-width:992px) {
        bottom: -16px;
        left: 278px;
    }
    @media (min-width:1200px) {
        bottom: -17px;
        left: 325px;
    }
    @media (min-width:1400px) {
        bottom: -16px;
        left: 370px;
    }
  }
  label{
        font-size: 18px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 25px;
        color: #262729;
        margin: 0 15px;
    }
`

Pricing.Badge = styled(Box)`
    color:#262729;
`
Pricing.Title = styled(Heading)`
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    @media (min-width:768px) {
        font-size: 35px;
    }

    @media (min-width:992px) {
        font-size: 45px;
    }
`
Pricing.Content = styled(Heading)`
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    @media (min-width:768px) {
        font-size: 16px;
    }

    @media (min-width:992px) {
        font-size: 18px;
    }
`
Pricing.Subtitle = styled(Heading)`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 28px;
    position: relative;
    padding-left: 60px;
    display: inline-block;
    color: #6001d3;

    &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    bottom: 9px;
    width: 41px;
    height: 1px;
    background-color: #6001d3;
    }
     
`
Pricing.Text = styled(Paragraph)`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.77777;
    @media (min-width:768px) {
        font-size: 18px;
    }
`



Pricing.Box = styled(Box)`

`


export default Pricing;