import React, { useState} from 'react'
import { Col, Row , Container } from 'react-bootstrap'
import Pricing from './style'
import 'bootstrap-icons/font/bootstrap-icons.css';
import Modal from 'react-bootstrap/Modal';
import PopupComponent from './Components/Calendly/popupwidget';
import Card from 'react-bootstrap/Card';
//import InnerShape from './Components/InnerPageShapeOne'

const pricingValues ={
  cardOne : {
    monthly:{
      price: 69,
      
    },
    yearly:{
      price:690,
      
    }
  },
  cardTwo : {
    monthly:{
      price: 79,
      
    },
    yearly:{
      price: 790,
      
    }
  },
  
}

export default function PricingSection({...rest}){
  const [yearly,setYearly] = React.useState(true);
  const {cardOne, cardTwo} =  pricingValues;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
return(
<Pricing {...rest}>
  <Container>
  <Pricing.Box pb="40px" pbXL="80px">
  <Row className="align-items-end justify-content-center ">
        <Col xs="12" >
          <Pricing.Box pb="30px" pbXL="60px" className="text-center text-lg-start">
            <Pricing.Title>Pricing</Pricing.Title>
            <Pricing.Content className="mt-4">Unlock the full potential of LinkedIn with our automated tools. Select a plan that suits your needs.</Pricing.Content>
            <Pricing.Content className="mt-4">No gimmicks, cancel anytime.</Pricing.Content>
          </Pricing.Box>
        </Col>
  </Row> 
  <Row className="justify-content-center">
         <Col xs="12" className="col-lg-6 col-md-6 col-sm-6 col-xs-10 mt-4" >
          {/* <Pricing.Button className="pricing-btn">
            <label className="mb-3 mb-lg-0">Monthly</label>
            <div className="toggle-btn form-switch mb-3 mb-lg-0">
              <input className="form-check-input btn-toggle price-deck-trigger" type="checkbox" onChange={() => setYearly(!yearly)} />
            </div>
            <label className="mb-3 mb-lg-0">Yearly</label>
            <Pricing.Badge className="badge bg-yellow mb-3 mb-lg-0" as="h3" >2 months FREE</Pricing.Badge>
          </Pricing.Button> */}
        <Pricing.Toggle>
        <div class="pricing-toggle">
          <input type="radio" id="pricing-toggle-monthly" name="pricing" value="monthly" onChange={() => setYearly(!yearly)} checked={yearly}/>
          <label className="radio-button" for="pricing-toggle-monthly"> Monthly billing</label>

          <input type="radio" id="pricing-toggle-annually" name="pricing" value="annually" onChange={() => setYearly(!yearly)}/>
          <label className="radio-button" for="pricing-toggle-annually"> Annual billing</label>
          
        </div>
        <Pricing.Badge className="badge bg-yellow mb-3 mb-lg-0" as="h4" >2 months free</Pricing.Badge>
        </Pricing.Toggle>
        </Col>
    </Row>
    </Pricing.Box>
    <Row className="justify-content-center">
      {/* Single Table */}
      <div className="table-responsive-lg aos-init aos-animate" data-aos="fade-up">
            <table className="table table-lg table-hover">
                <thead className="border-bottom">
                    <tr className="text-center">
                        <th scope="col" className="text-left">
                            <div className="mb-6">
                                <div className="h5 font-weight-bold"></div>
                                <p className="font-weight-normal text-muted font-size-md d-lg-none">Scroll to view more plans </p>
                            </div>
                        </th>
                        <th scope="col" className="text-center text-nowrap">
                        <Card className='shadow'>
                          <Card.Header size="small" style={{ backgroundColor: "#0f89ff", }}></Card.Header>
                          <Card.Body>
                            <Card.Title><div className="h5 font-weight-bold mb-0">Starter</div></Card.Title>
                            <Card.Text>
                            <p className="font-weight-normal text-muted">(LinkedIn only)</p>
                            </Card.Text>
                            <div className="h5 font-weight-bold mb-3">${yearly? cardOne.monthly.price : cardOne.yearly.price}/{yearly? "seat" : "seat"}</div>
                            <a href="//account.we-connect.io/signup" className="btn btn-dodger-blue btn-sm waves-effect text-white rounded" target="_blank" role="button">Start Free Trial</a>
                          </Card.Body>
                        </Card>
                        </th>
                        <th scope="col" className="text-center text-nowrap">
                        <Card className='shadow-lg'>
                          <Card.Header style={{ backgroundColor: '#5338fc', color: "white", fontSize:"12px", fontWeight:"400" }} as='h6'>MOST POPULAR</Card.Header>
                          <Card.Body>
                            <Card.Title><div className="h5 font-weight-bold mb-0">Professional</div></Card.Title>
                            <Card.Text>
                            <p className="font-weight-normal text-muted">(LinkedIn + Email)</p>
                            </Card.Text>
                            <div className="h5 font-weight-bold mb-3">${yearly? cardTwo.monthly.price : cardTwo.yearly.price}/{yearly? "seat" : "seat"}</div>
                            <a href="//account.we-connect.io/signup" className="btn btn-primary btn-sm waves-effect rounded" target="_blank" role="button">Start Free Trial</a>
                          </Card.Body>
                        </Card>
                            
                        </th>
                        <th scope="col" className="text-center text-nowrap">
                        <Card  className='shadow mx-2'>
                          <Card.Header style={{ backgroundColor: '#0f89ff' }}></Card.Header>
                          <Card.Body>
                            <Card.Title><div className="h5 font-weight-bold mb-0">Enterprise</div></Card.Title>
                            <Card.Text>
                            <p className="font-weight-normal text-muted">Let's talk</p>
                            </Card.Text>
                            <p className="font-weight-bold text-muted">10 seats plus</p>
                            <a className="btn btn-white btn-outline-primary btn-sm waves-effect rounded" onClick={handleShow} role="button">Book a Demo</a>
                          </Card.Body>
                        </Card>
                            <Modal show={show} onHide={handleClose}>
                            <Modal.Body>
                            <PopupComponent />
                            </Modal.Body>
                            </Modal>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='border-bottom'>
                        <th scope="row" className="h6 py-3 font-weight-semibold text-nowrap border-0 mb-0"></th>
                        <td className="py-3 border-0 text-center">Ideal for individuals<br/> starting to grow their<br/> network.</td>
                        <td className="py-3 border-0 text-center">Perfect for professionals<br/> seeking to maximize<br/> outreach and engagement</td>
                        <td className="py-3 border-0 text-center">Designed for teams,<br/> offering advanced features<br/> and dedicated support.</td>
                    </tr>
                    <tr>
                        <th scope="row" className=""><Pricing.Text>LinkedIn automation with<br/> unlimited smart campaigns</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>
                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Reporting & analytics</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center">Advanced team reporting & analytics</td>
                        <td className="text-center">Advanced team reporting & analytics</td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Personalized invitations</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Send files & voice notes</Pricing.Text>
                        </th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Connection tracking</Pricing.Text>
                            
                        </th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Automated birthday and<br/> congratulatory messages</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>
                    
                    <tr >
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Dedicated IP address</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr >
                        <th className="font-weight-normal" scope="row"><Pricing.Text>BYOIP (Bring your own IP)</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr >
                        <th className="font-weight-normal" scope="row"><Pricing.Text>AI Assist</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Webhooks & Zapier integration</Pricing.Text>
                        </th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>API access</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Email automation</Pricing.Text></th>
                        <td className="text-center">-</td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Multi-channel smart campaigns leveraging<br/> both LinkedIn and email automation</Pricing.Text> </th>
                        <td className="text-center">-</td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr >
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Behavior based email campaigns</Pricing.Text></th>
                        <td className="text-center">-</td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>CRM integrations</Pricing.Text></th>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#5338fc"}}></i></td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Custom integrations available</Pricing.Text>
                            
                        </th>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th scope="row" className="font-weight-normal"><Pricing.Text>Training sessions on-demand</Pricing.Text></th>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center"><i className="bi bi-check-circle-fill h5" style={{color:"#0f89ff"}}></i></td>
                    </tr>

                    <tr>
                        <th className="font-weight-normal" scope="row"><Pricing.Text>Support</Pricing.Text></th>
                        <td className="text-center">Live Chat</td>
                        <td className="text-center">Live Chat/Email</td>
                        <td className="text-center">Live Chat/Email/Phone</td>
                    </tr>

                    <tr className="text-center bg-white">
                        <th scope="col"></th>
                        <th scope="col" className="text-center text-nowrap">
                        <a href="//account.we-connect.io/signup" className="btn btn-dodger-blue btn-sm waves-effect text-white mt-3 rounded" target="_blank" role="button">Start Free Trial</a>
                        </th>
                        <th scope="col" className="text-center text-nowrap">
                        <a href="//account.we-connect.io/signup" className="btn btn-primary btn-sm waves-effect mt-3 rounded" target="_blank" role="button">Start Free Trial</a>
                        </th>
                        <th scope="col" className="text-center text-nowrap">
                        <a className="btn btn-white btn-outline-primary btn-sm waves-effect mt-3 rounded" onClick={handleShow} role="button">Book a Demo</a>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
      
    </Row>
    <Pricing.Box className="text-center">
    <Pricing.Content className="mt-6">All plans include a 14-day free trial with full feature access. No credit card required.</Pricing.Content>
    </Pricing.Box>
    

  </Container>
</Pricing>

)
}